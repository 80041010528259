.home {
    font-family: sans-serif;
    max-width: 450px;
    width: 90%;
    margin: 0 auto;
    text-align: center;
  }

  .card {
    line-height: 1.1;
  }

  .social-links {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .link-container {
    width: 32%;
  }
  
  .social-link {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    margin-bottom: 5px;
  }
  
  .icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
  }
  
  .link-label {
    text-align: center;
    font-style: italic;
  }
  

  /* Custom CSS for dark border */
.card-border-dark {
  border: 1px solid #d1d1d1; /* Replace with your desired dark color */
}
/* Custom CSS for dividing lines and text styles */
.card-divider {
  border-top: 1px solid #d1d1d1; /* Faint grey color */
  margin: 10px 0;
}

.card-text {
  text-align: justify;
  font-size: 14px;
  font-style: italic;
}

.custom-btn {
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  transition: all 0.3s ease;
}

.custom-btn:hover {
  box-shadow: 0 0 15px rgba(0,0,0,0.2);
  transform: scale(1.02);
}



  
  .outer-home-container {
    /* display: flex; */
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;
    /* height: 100vh; */
    width: 100%;
    /* background-color: #333333;
    opacity: 0.7;
    filter:(opacity=50); */
  
    
  }

  .btn-default a {
    color: #333;
  }

  .btn-primary a {
    color: #FFF;
  }

  .btn-default a:hover{
    text-decoration: none;
  }
  .btn-primary a:hover{
    text-decoration: none;
  }