@import url(https://fonts.googleapis.com/css2?family=Comfortaa&display=swap);
.App {
  font-family: sans-serif;
  text-align: center;
  /* max-width: 480px;
  margin: 0 auto; */
}
.btn, #tipAmount {
  font-size: 24px;
}
.lb-sm {
  font-size: 12px;
}

.lb-md {
  font-size: 16px;
}

.lb-lg {
  font-size: 20px;
}

.payBtn {
  height: 80px;
  font-size: 36px;
}
.upperNav {
  width: 100%;
  z-index: 2;
}

body {
  padding-top: 0px;  /* adjust this value according to your .upperNav and .lowerNav height */
}

.nav-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  margin: 0 auto;
  width: 90%;
}

.brandText {
  /* font-size: .4rem; */
  font-weight: bold;
  color: #333333;
}

.home {
    font-family: sans-serif;
    max-width: 450px;
    width: 90%;
    margin: 0 auto;
    text-align: center;
  }

  .card {
    line-height: 1.1;
  }

  .social-links {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .link-container {
    width: 32%;
  }
  
  .social-link {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    margin-bottom: 5px;
  }
  
  .icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
  }
  
  .link-label {
    text-align: center;
    font-style: italic;
  }
  

  /* Custom CSS for dark border */
.card-border-dark {
  border: 1px solid #d1d1d1; /* Replace with your desired dark color */
}
/* Custom CSS for dividing lines and text styles */
.card-divider {
  border-top: 1px solid #d1d1d1; /* Faint grey color */
  margin: 10px 0;
}

.card-text {
  text-align: justify;
  font-size: 14px;
  font-style: italic;
}

.custom-btn {
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  transition: all 0.3s ease;
}

.custom-btn:hover {
  box-shadow: 0 0 15px rgba(0,0,0,0.2);
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
}



  
  .outer-home-container {
    /* display: flex; */
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;
    /* height: 100vh; */
    width: 100%;
    /* background-color: #333333;
    opacity: 0.7;
    filter:(opacity=50); */
  
    
  }

  .btn-default a {
    color: #333;
  }

  .btn-primary a {
    color: #FFF;
  }

  .btn-default a:hover{
    text-decoration: none;
  }
  .btn-primary a:hover{
    text-decoration: none;
  }
.App1 {
  font-family: sans-serif;
  text-align: center;
  max-width: 480px;
  margin: 0 auto;
}
.btn, #tipAmount {
  font-size: 24px;
}
.lb-sm {
  font-size: 12px;
}

.lb-md {
  font-size: 16px;
}

.lb-lg {
  font-size: 20px;
}

.payBtn {
  height: 80px;
  font-size: 36px;
}
.App2 {
  font-family: sans-serif;
  text-align: center;
  max-width: 480px;
  margin: 0 auto;
}
.btn, #tipAmount {
  font-size: 24px;
}
.lb-sm {
  font-size: 12px;
}

.lb-md {
  font-size: 16px;
}

.lb-lg {
  font-size: 20px;
}

.payBtn {
  height: 80px;
  font-size: 36px;
}

#invoiceAmount, #tipAmount, #description { font-size: 30px; }
.App3 {
  font-family: sans-serif;
  text-align: center;
  max-width: 480px;
  margin: 0 auto;
}
.btn, #tipAmount {
  font-size: 24px;
}
.lb-sm {
  font-size: 12px;
}

.lb-md {
  font-size: 16px;
}

.lb-lg {
  font-size: 20px;
}

.payBtn {
  height: 80px;
  font-size: 36px;
}

.App3 > div > form > input:nth-child(n), #tipAmount { font-size: 30px; }
.pay {
  font-family: sans-serif;
  max-width: 450px;
  width: 90%;
  margin: 0 auto;
  text-align: center;
}
.page {
  font-family: sans-serif;
  max-width: 450px;
  width: 90%;
  margin: 0 auto;
  text-align: center;
}
.page {
  font-family: sans-serif;
  max-width: 450px;
  width: 90%;
  margin: 0 auto;
  text-align: center;
}
.page {
  font-family: sans-serif;
  text-align: center;
  max-width: 480px;
  margin: 0 auto;
}
.btn, #tipAmount {
  font-size: 24px;
}
.lb-sm {
  font-size: 12px;
}

.lb-md {
  font-size: 16px;
}

.lb-lg {
  font-size: 20px;
}

.payBtn {
  height: 80px;
  font-size: 36px;
}
.Terms {
  font-family: sans-serif;
  text-align: center;
  max-width: 480px;
  margin: 0 auto;
}
.btn, #tipAmount {
  font-size: 24px;
}
.lb-sm {
  font-size: 12px;
}

.lb-md {
  font-size: 16px;
}

.lb-lg {
  font-size: 20px;
}

.payBtn {
  height: 80px;
  font-size: 36px;
}
.Privacy {
  font-family: sans-serif;
  text-align: center;
  max-width: 480px;
  margin: 0 auto;
}
.btn, #tipAmount {
  font-size: 24px;
}
.lb-sm {
  font-size: 12px;
}

.lb-md {
  font-size: 16px;
}

.lb-lg {
  font-size: 20px;
}

.payBtn {
  height: 80px;
  font-size: 36px;
}
.page {
    font-family: sans-serif;
    max-width: 450px;
    width: 90%;
    margin: 0 auto;
    text-align: center;
  }

  /* Custom CSS for dark border */
.card-border-dark {
  border: 1px solid #d1d1d1; /* Replace with your desired dark color */
}
/* Custom CSS for dividing lines and text styles */
.card-divider {
  border-top: 1px solid #d1d1d1; /* Faint grey color */
  margin: 10px 0;
}

.card-text {
  text-align: justify;
  font-size: 14px;
  font-style: italic;
}

.custom-btn {
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  transition: all 0.3s ease;
}

.custom-btn:hover {
  box-shadow: 0 0 15px rgba(0,0,0,0.2);
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
}



  
  .outer-page-container {
    /* display: flex; */
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;
    /* height: 100vh; */
    width: 100%;
    /* background-color: #333333;
    opacity: 0.7;
    filter:(opacity=50); */
  
    
  }

  .btn-default a {
    color: #333;
  }

  .btn-primary a {
    color: #FFF;
  }

  .btn-default a:hover{
    text-decoration: none;
  }
  .btn-primary a:hover{
    text-decoration: none;
  }
/* .auth {
  background: linear-gradient(90deg, #4b6cb7 0%, #182848 100%);
} */

.login {
  width: 360px;
  padding: 8% 0 0;
  margin: auto;
  font-family: 'Comfortaa', cursive;
}

.form {
  position: relative;
  z-index: 1;
  background: #FFFFFF;
  border-radius: 10px;
  max-width: 360px;
  margin: 0 auto 100px;
  padding: 45px;
  text-align: center;
}

.form input {
  outline: 0;
  background: #f2f2f2;
  width: 100%;
  border: 0;
  border-radius: 5px;
  margin: 0 0 15px;
  padding: 15px;
  box-sizing: border-box;
  font-size: 14px;
  font-family: 'Comfortaa', cursive;
}

.form input:focus {
  background: #dbdbdb;
}

.form button {
  font-family: 'Comfortaa', cursive;
  text-transform: uppercase;
  outline: 0;
  background: #4b6cb7;
  width: 100%;
  border: 0;
  border-radius: 5px;
  padding: 15px;
  color: #FFFFFF;
  font-size: 14px;
  transition: all 0.3 ease;
  cursor: pointer;
}

.form button:active {
  background: #395591;
}

.form span {
  font-size: 40px;
  color: #4b6cb7;
  margin-bottom: 25px;
  display: block;
}

.form p.error {
  margin: 0 0 10px 10px;
  text-align: left;
  font-size: 10px;
  color: red;
}
.home {
    font-family: sans-serif;
    max-width: 450px;
    width: 90%;
    margin: 0 auto;
    text-align: center;
  }
  .footer {
    font-family: sans-serif;
    max-width: 450px;
    width: 90%;
    margin: 0 auto;
    text-align: center;
  }

  /* set font size for all button elements on page */
  .btn {
    font-size: '18px';
  }


  .outer-home-container {
    /* display: flex; */
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;
    /* height: 100vh; */
    /* width: 100%; */
    max-width: 450px;
    width: 90%;
    margin: 0 auto;
    text-align: center;
    /* background-color: #333333;
    opacity: 0.7;
    filter:(opacity=50); */
  
    
  }

  .btn-default a {
    color: #333;
  }

  .btn-primary a {
    color: #FFF;
  }

  .btn-default a:hover{
    text-decoration: none;
  }
  .btn-primary a:hover{
    text-decoration: none;
  }

  /* Define the error styling for input fields */
.input-field.error {
  border-color: red;
}

/* Define the error message styling */
.input-feedback {
  color: red;
  margin-top: 5px;
}

/* Style your fields normally */
.input-field {
  /* Your regular styles */

  /* style the input field to be full width */
  width: 100%;
}

/* Change the field border to red when an error message is present */
.input-feedback + .input-field {
  border-color: red;
}

/* Style your error messages */
.input-feedback {
  color: red;
}

/* style label to be above the input */
label {
  display: block;
  margin-bottom: 1px;
}

#description {
  font-size: 14px;
}
